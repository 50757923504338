import { navigate } from "@reach/router"
import React from "react"
import { Tab, Tabs } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import ActiveTab from "../components/dashboard/ActiveTab"
import Layout from "../components/utils/layout"
import Loader from "../components/utils/Loader"
import PendingTab from "../components/dashboard/PendingTab"
import SEO from "../components/utils/seo"
import SubmittedTab from "../components/dashboard/ReviewTab"
import TitleLine from "../components/utils/TitleLine"
import getUserData from "../scripts/getUserData"
import "../styles/createStudentAccountPage.css"
import StudentStepper from "../components/dashboard/StudentStepper"
import logout from "../scripts/logout"
import axios from "axios"
import updateSlackJoined from "../scripts/updateSlackJoined"
import { auth } from "../firebase"
import dots from "../images/bgs/dots.svg"

class NewStudentDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      run: false,
      authenticated: null,
      authExecuted: false,
      refreshTabs: [false, false, false, false],
      currentKey: "signups",
      numPending: 0,
      numActive: 0,
      numReview: 0,
      screenWidth: 0,
    }
    this.refresh = this.refresh.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.init = this.init.bind(this)
    this.setRefresh = this.setRefresh.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  updateWindowDimensions() {
    if (this.state.screenWidth !== window.innerWidth) {
      this.setState({ screenWidth: window.innerWidth })
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions)

    auth.onAuthStateChanged(user => {
      if (!user) {
        this.setState({ authenticated: false })
        this.navigateToHome()
      } else {
        if (!user.emailVerified) {
          logout().then(() => {
            this.navigateToHome()
          })
        } else {
          this.checkIfStudent()
          this.setState({ authenticated: true })
        }
      }
    })
  }

  setRefresh(newRefresh) {
    this.setState({ refreshTabs: newRefresh })
  }

  init() {
    if (this.state.authenticated) {
      this.setState({ initialized: true })
      if (this.state.authenticated) {
        this.setState({ initialized: true })
        let user_id = auth.currentUser.uid
        getUserData(user_id)
          .then(data => {
            if (data["userType"] !== "student") {
              navigate("/")
              return
            }
            let newNumAccepted = data["activeProjects"].length
            let newNumReview = Object.keys(data["reviewProjects"]).length
            let newNumPending = data["pendingProjects"].length
            this.setState({
              numPending: newNumPending,
              numActive: newNumAccepted,
              numReview: newNumReview,
            })
          })
          .catch(error => {
            console.log(error)
            //TODO Error handle
          })
      }
    }
  }

  navigateToHome() {
    navigate("/")
  }

  refresh() {
    this.setState({ initialized: false })
  }

  checkIfStudent() {
    let user = auth.currentUser
    getUserData(user.uid)
      .then(data => {
        if (data) {
          if (data["userType"] !== "student") {
            this.setState({ authExecuted: false })
            this.navigateTo("/404/")
          } else if (!data.joinedSlack) {
            axios
              .get(
                "https://us-central1-test-51594.cloudfunctions.net/slackVerif-verifyJoined?email=" +
                  user.email
              )
              .then(response => {
                if (response.data) {
                  this.setState({ authExecuted: true })
                  updateSlackJoined(user.uid).catch(error => {
                    console.log(error)
                  })
                } else {
                  navigate("/JoinSlack/")
                }
              })
              .catch(error => {
                console.log(error)
              })
          } else {
            this.setState({ authExecuted: true })
          }
        }
      })
      .catch(error => {
        console.log(error)
        //TODO Error handle
      })
  }

  navigateTo(location) {
    navigate(location)
  }

  handleSelect(key) {
    this.setState({ currentKey: key })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  render() {
    if (!this.state.initialized) {
      this.init()
    }

    if (this.state.authExecuted) {
      let postedTitle = (
        <div className="dashboardTabTitles">
          <span>Pending Signups&nbsp;</span>
          <span className="redText">({this.state.numPending})</span>
        </div>
      )
      let activeTitle = (
        <div className="dashboardTabTitles">
          <span>Active Projects&nbsp;</span>
          <span className="redText">({this.state.numActive})</span>
        </div>
      )
      let submittedTitle = (
        <div className="dashboardTabTitles">
          <span>Volunteer Hour Requests&nbsp;</span>
          <span className="redText">({this.state.numReview})</span>
        </div>
      )
      let full = false
      if (this.state.currentKey === "signups") {
        full = this.state.numPending <= 2
      } else if (this.state.currentKey === "active") {
        full = this.state.numActive <= 2
      } else {
        full = this.state.numReview <= 2
      }
      return (
        <>
          <div class="createStudentAccountPage">
            <Layout image={dots} full={full}>
              <>
                <SEO title="Student Portal" />
                <div className="selector">
                  <Container className="tbMargins ">
                    <TitleLine title="Student Portal" />
                  </Container>
                </div>
                <Container className="tabalign">
                  <Tabs
                    defaultActiveKey="active"
                    activeKey={this.state.currentKey}
                    onSelect={this.handleSelect}
                    className="tabproperties"
                  >
                    {/* <Tab eventKey="t0" title="What's the Process?">
                      <Container fluid={true} className="">
                        <StudentStepper />
                      </Container>
                    </Tab> */}
                    <Tab eventKey="signups" title={postedTitle}>
                      <PendingTab
                        refresh={this.refresh}
                        userType="student"
                        refreshTabs={this.state.refreshTabs}
                        setRefresh={this.setRefresh}
                      ></PendingTab>
                    </Tab>
                    <Tab eventKey="active" title={activeTitle}>
                      <ActiveTab
                        userType="student"
                        refresh={this.refresh}
                        refreshTabs={this.state.refreshTabs}
                        setRefresh={this.setRefresh}
                      ></ActiveTab>
                    </Tab>
                    <Tab eventKey="volhours" title={submittedTitle}>
                      <SubmittedTab
                        userType="student"
                        refresh={this.refresh}
                        refreshTabs={this.state.refreshTabs}
                        setRefresh={this.setRefresh}
                      ></SubmittedTab>
                    </Tab>
                  </Tabs>
                </Container>
              </>
            </Layout>
          </div>
        </>
      )
    } else {
      return (
        <Layout full>
          <SEO title="Student Portal" />
          <Loader />
        </Layout>
      )
    }
  }
}

export default NewStudentDashboard
